<template>
  <div
    class="tab-pane fade"
    id="order-setting"
    role="tabpanel"
    aria-labelledby="order-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Sipariş</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Without Register -->
          <WeSettingCard
            name="member-without"
            title="Üyeliksiz Alışveriş"
            description="Üyeliksiz alışveriş yapılmasına izin ver"
            v-model="data.without_member"
          />
          <!-- Without Register -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Gift Note -->
          <WeSettingCard
            name="gift-note"
            title="Hediye Notu"
            description="Hediye notu girilmesine izin ver"
            v-model="data.gift_note"
          />
          <!-- Gift Note -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Gift Note -->
          <WeSettingCard
            name="required_tc_no"
            title="TC Kimlik Numarası"
            description="Siparişte TC Kimlik numarasını zorunlu tut"
            v-model="data.required_tc_no"
          />
          <!-- Gift Note -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Order Note -->
          <WeSettingCard
            name="order-coupon-active"
            title="Sipariş Notu"
            description="Sipariş notu eklenmesine izin ver"
            v-model="data.note_active"
          />

          <WeSettingCard
            v-if="data.note_active"
            name="order-note-require-active"
            title="Zorunlu mu?"
            description="Sipariş notu alanı zorunlu mu?"
            v-model="data.note_is_require"
          />
         
          <div class="col-12 col-lg-12 m-0 p-0" v-if="data.note_active">
            <div class="form-row align-items-center mb-3">
              <div class="col-12 col-lg">
                <label class="custom-label">Min Karakter Limiti</label>
                <div class="d-block">
                  <small class="text-muted"
                    >Sipariş notu alanı için girilebilecek minimum karakter sayısı
                  </small>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <WeInput
                  type="number"
                  v-bind:formGroup="false"
                  v-model="data.note_char_min_length"
                />
              </div>
            </div>
          </div>
           <div class="col-12 col-lg-12 m-0 p-0" v-if="data.note_active">
            <div class="form-row align-items-center mb-3">
              <div class="col-12 col-lg">
                <label class="custom-label">Max Karakter Limiti</label>
                <div class="d-block">
                  <small class="text-muted"
                    >Sipariş notu alanı için girilebilecek maximum karakter sayısı
                  </small>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <WeInput
                  type="number"
                  v-bind:formGroup="false"
                  v-model="data.note_char_max_length"
                />
              </div>
            </div>
          </div>
          <!-- ./Order Note -->
        </div>

        <div class="col-12 col-lg-6">
          <!-- Whatsapp -->
          <WeSettingCard
            name="whatsapp"
            title="WhatsApp"
            v-bind:description="
              isShowWhatsapp
                ? 'WhatsApp üzerinden siparişlere izin veri aktif edebilmek için Site Ayarları > WhatsApp Butonu Göster'
                : 'WhatsApp üzerinden siparişlere izin ver'
            "
            v-model="data.whatsapp"
            v-bind:disabled="isShowWhatsapp"
          />
          <WeMentionData
            class="ck-editor-seo"
            v-model="mention"
            v-bind:use-editor="true"
            v-bind:mentions="getMentions"
            v-bind:show-limits="false"
            v-if="data.whatsapp == 1 ? true : false"
            v-bind:title="'Hazır Mesajı Girin'"
            v-bind:description="'Whatsapp satışlarında gönderilecek hazır mesajı belirle'"
          />
          <!-- Whatsapp -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Order Note -->
          <WeSettingCard
            name="order-note-active"
            title="Kupon Kodu"
            description="Kupon kodu kullanımına izin ver"
            v-model="data.coupon_active"
          />
          <!-- ./Order Note -->
        </div>
      </div>
      <hr />

      <div class="row">
        <!-- Cancel/Refund Limit Day -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">İptal/İade Süresi</label>
              <div class="d-block">
                <small class="text-muted"
                  >İptal/İade işlemi için en fazla geçerli gün</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                v-model="data.cancel_or_refund"
              />
            </div>
          </div>
        </div>
        <!-- Cancel/Refund Limit Day -->

        <!-- Max Refund Day -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">İade Süresi</label>
              <div class="d-block">
                <small class="text-muted"
                  >İade işlemi için en fazla geçerli gün</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                v-model="data.max_refund_day"
              />
            </div>
          </div>
        </div>
        <!-- Max Refund Day -->

        <!-- Cancel Limit Day -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">İptal Süresi - Havale</label>
              <div class="d-block">
                <small class="text-muted"
                  >Havale ile ödemede, sipariş durumunun iptal edildi olarak
                  ayarlanmadan önce beklenmesi gereken gün</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <input
                type="text"
                class="form-control"
                v-model="data.transfer_expired_at"
              />
            </div>
          </div>
        </div>
        <!-- Cancel Limit Day -->

        <!-- Transfer Discount -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Havale İndirim Oranı</label>
              <div class="d-block">
                <small class="text-muted"
                  >Havale ile ödemede uygulanacak indirim oranı</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                v-model="data.bank_transfer_discount"
                v-bind:filter-number="true"
                prepend="%"
              />
            </div>
          </div>
        </div>
        <!-- Transfer Discount -->

        <!-- Transfer Timeout -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Havale Zaman Aşımı</label>
              <div class="d-block">
                <small class="text-muted"
                  >Belirtilen dakika içerisinde havale yapılmazsa sipariş iptal
                  edilir. Boş bırakılabilir.</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                v-model="data.bank_transfer_timeout"
                v-bind:filter-number="true"
                append="Dk."
              />
            </div>
          </div>
        </div>
        <!-- Transfer Timeout -->

        <!-- Order Expiry -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Son Sipariş Saati</label>
              <div class="d-block">
                <small class="text-muted"
                  >Belirlenen saate kadar sipariş verilebilir. Saat kısıtlaması
                  istemiyorsanız boş bırakın.</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="d-flex align-items-center">
                <input
                  type="time"
                  class="form-control"
                  v-model="data.expiry_time"
                />
                <span
                  class="btn btn-danger ml-2"
                  v-if="data.expiry_time"
                  v-on:click="clearTime"
                  ><i class="fas fa-trash"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
        <!-- Order Expiry -->

        <!-- Notification Email -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Bildirim E-Posta Adresi</label>
              <div class="d-block">
                <small class="text-muted"
                  >Sipariş bildirimlerinin gönderileceği e-posta adresi</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                v-model="data.notification_email"
              />
            </div>
          </div>
        </div>
        <!-- Notification Email -->

        <!-- Notification GSM -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Bildirim Telefon Numarası</label>
              <div class="d-block">
                <small class="text-muted"
                  >Sipariş bildirimlerinin gönderileceği telefon numarası</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WePhone
                v-model="data.notification_gsm"
                v-bind:show-label="false"
                v-bind:show-flag="false"
                v-bind:label="$t('phoneNumber')"
              />
            </div>
          </div>
        </div>
        <!-- Notification GSM -->

        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Tahsilat Linki Süresi</label>
              <div class="d-block">
                <small class="text-muted"
                  >Gönderilen tahsilat linklerinin kaç dakika sonra sonlanması
                  gerektiğini belirtiniz.</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                v-model="data.without_order_timeout_minute"
                v-bind:filter-number="true"
                append="Dk."
              />
            </div>
          </div>
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
export default {
  name: "Order",
  data() {
    return {
      isShowWhatsapp: false,
      data: {
        transfer_expired_at: this.settings["order.transfer_expired_at"],
        bank_transfer_discount: this.settings["order.bank_transfer_discount"],
        bank_transfer_timeout: this.settings["order.bank_transfer_timeout"],
        without_member: this.settings["order.without_member"] == "1" ? 1 : 0,
        cancel_or_refund: this.settings["order.cancel_or_refund"],
        whatsapp: this.settings["order.whatsapp"] == "1" ? 1 : 0,
        max_refund_day: this.settings["order.max_refund_day"],
        gift_note: this.settings["order.gift_note"] == "1" ? 1 : 0,
        required_tc_no: this.settings["order.required_tc_no"] == "1" ? 1 : 0,
        note_active: this.settings["order.note_active"] == "1" ? 1 : 0,
        note_char_max_length: this.settings["order.note_char_max_length"] ?? 255,
        note_char_min_length: this.settings["order.note_char_min_length"] ?? 0,
        note_is_require: this.settings["order.note_is_require"] == "1" ? 1 : 0,
        coupon_active: this.settings["order.coupon_active"] == "1" ? 1 : 0,
        expiry_time: this.settings["order.expiry_time"],
        notification_email: this.settings["order.notification_email"],
        without_order_timeout_minute:
          this.settings["site.without_order_timeout_minute"] ?? 30,
        notification_gsm: this.settings["order.notification_gsm"],
      },
      filter: {
        productName: null,
        productURL: null,
        PproductSku: null,
      },
      isMentionHover: false,
      mention: {
        text:
          this.settings["order.whatsapp_message"] == undefined
            ? "<span class='mention' data-mention='@ProductName'>@ProductName</span> ürününü satın almak istiyorum."
            : this.settings["order.whatsapp_message"],
      },
      mentions: [
        {
          id: "@ProductName",
          name: "Ürün Adı",
        },
        {
          id: "@ProductSku",
          name: "Ürün SKU",
        },
        {
          id: "@ProductURL",
          name: "Ürün Url",
        },
      ],
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    clearTime() {
      this.data.expiry_time = null;
    },
    onSave() {
      this.settings["order.transfer_expired_at"] =
        this.data.transfer_expired_at;
      this.settings["order.bank_transfer_discount"] =
        this.data.bank_transfer_discount;
      this.settings["order.bank_transfer_timeout"] =
        this.data.bank_transfer_timeout;
      this.settings["order.without_member"] = this.data.without_member;
      this.settings["order.cancel_or_refund"] = this.data.cancel_or_refund;
      this.settings["order.whatsapp"] = this.data.whatsapp;
      this.settings["order.whatsapp_message"] = this.mention.text;
      this.settings["order.max_refund_day"] = this.data.max_refund_day;
      this.settings["order.gift_note"] = this.data.gift_note;
      this.settings["order.required_tc_no"] = this.data.required_tc_no;
      this.settings["order.note_active"] = this.data.note_active;
      this.settings["order.note_is_require"] = this.data.note_is_require;
      this.settings["order.note_char_max_length"] = this.data.note_char_max_length;
      this.settings["order.note_char_min_length"] = this.data.note_char_min_length;
      this.settings["order.coupon_active"] = this.data.coupon_active;
      this.settings["order.expiry_time"] = this.data.expiry_time;
      this.settings["order.notification_email"] = this.data.notification_email;
      this.settings["site.without_order_timeout_minute"] =
        this.data.without_order_timeout_minute;
      this.settings["order.notification_gsm"] = this.data.notification_gsm;
      this.$parent.$emit("on-save", this.settings);
    },
  },
  mounted() {
    this.data.whatsapp =
      this.settings["site.show_whatsapp_button"] == 1
        ? this.data.whatsapp
        : this.settings["site.show_whatsapp_button"];
    this.isShowWhatsapp =
      this.settings["site.show_whatsapp_button"] == 1 ? false : true;
    this.$root.$on("changeWhatsappShow", (payload) => {
      this.isShowWhatsapp = payload == 1 ? false : true;
      this.data.whatsapp = payload == 1 ? this.data.whatsapp : payload;
    });

    var wp_message = this.settings["order.whatsapp_message"];
    if (wp_message) {
      this.mentions.forEach((result) => {
        if (wp_message.toString().indexOf(result.id) != -1) {
          wp_message = wp_message
            .toString()
            .replace(
              result.id,
              `<span class='mention' data-mention='${result.id}'>${result.id}</span>`
            );
        }
      });
      this.mention.text = wp_message;
    }
  },
  computed: {
    getMentions() {
      return [...this.mentions];
    },
  },
};
</script>

<style scoped>
</style>
